import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CalculatorOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import { Button as AntButton, Modal, Row } from 'antd';
import { Table, Warning } from 'src/components';
import { customConfirm } from 'src/components/common/confirmModal';
import CanView from 'src/components/permission/canview';
import {
  ColumnsMain,
  customSummary,
  ColumnsDansMain,
  customDansSummary,
} from './dataStructure/data';
import { searchList } from './dataStructure/search';
import { NewFileTovchoo } from './newFileAdd';
import { start_loading, end_loading } from '../../../core/actions/loader';
import BurtgeList from './BurtgeList';
import { ErrorList } from './ErrorList';
import { ErrorListHurungu } from './ErrorListHurungu';
import { ErrorListDahin } from './ErrorListDahin';
import { NewFileAddDahin } from './fileAdd';

const UndsenHurunguTovchoo = () => {
  const dispatch = useDispatch();

  const query = useQuery();
  const history = useHistory();

  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const [sumType, seTsumType] = useState();
  const [selectedHurungu, seTselectedHurungu] = useState({});
  const [burtgel, seTburtgel] = useState(false);
  const [visibleEH, seTvisibleEH] = useState(false);

  const [visible, seTvisible] = useState({
    towcho: false,
    dahin: false,
  });

  const typesList = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];

  const [errorListHurungu, seTerrorListHurungu] = useState({
    error_type: 'hurungu',
    have_error: false,
    errors: [],
    burtgel_errors: {},
  });
  const [updated, seTupdated] = useState(false);
  const [errorList, seTerrorList] = useState({
    have_error: false,
    errors: [],
    burtgel_errors: {},
  });
  const [errorListDahin, seTerrorListDahin] = useState({
    have_error: false,
    errors: {},
  });

  // const [fileTovchoo, seTfileTovchoo] = useState(false);
  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        console.log(data?.content);
        setTableData(data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const { month } = data_date;
    const search = {
      type: sumType || 'hurungu',
      dans_dugaar: query.get('dans_dugaar'),
      hurungu: query.get('hurungu'),
      holbolt: query.get('holbolt'),
    };
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: {
        action_type: 'sanhuu_tovchoo_page',
        month,
        page,
        size,
        search,
      },
    });
    dispatch(start_loading({}));
  };

  const onSelectHurungu = (row) => {
    seTselectedHurungu(row);
    seTburtgel(true);
  };

  const clearPage = () => {
    const proplist = ['page', 'size'];
    let not_change = true;
    proplist.forEach((name) => {
      if (query.get(name)) {
        query.delete(name);
        not_change = false;
      }
    });
    if (not_change) {
      select_data();
    } else {
      history.push({ search: query.toString() });
    }
  };

  useEffect(() => {
    if (sumType) {
      clearPage();
    }
  }, [sumType]);

  useEffect(() => {
    select_data();
  }, [query, data_date]);

  const after_handleUploadDahin = (content) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        select_data();
        newAlert({ type: 'success', msg: data.message });
      } else {
        if (data?.content?.result_list) {
          seTerrorListDahin({
            have_error: true,
            errors: data?.content?.result_list,
          });
        }
        newAlert({ type: 'error', msg: data.message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: {
        action_type: 'dahin_unelgee_file_tailan',
        file_name: content?.json_array,
      },
    });
  };

  const cal_sanhuu_tovchoo = () => {
    socket.on('request_out', (data) => {
      if (data.status === '0') {
        socket.removeAllListeners('request_out');
        newAlert({ type: 'success', msg: data.message });
        dispatch(end_loading({}));
        select_data();
      } else if (data.status === '1') {
        socket.removeAllListeners('request_out');
        if (data?.content?.result_list) {
          console.log(data);
          if (data?.error_type === 'hurungu') {
            seTerrorListHurungu({
              error_type: 'hurungu',
              have_error: true,
              errors: data?.content?.result_list,
              burtgel_errors: [],
            });
          } else {
            seTerrorListHurungu({
              error_type: 'dans',
              have_error: true,
              errors: data?.content?.result_list,
              burtgel_errors: [],
            });
          }
        }
        newAlert({ type: 'error', msg: data.message });
        dispatch(end_loading({}));
        select_data();
      } else if (data.status === '3') {
        newAlert({ type: 'warning', msg: data.message });
      }
    });
    socket.emit('request_in', {
      channel: 'ts003_calculate',
      content: { action_type: 'calc_undsen_hurungu_tovchoo' },
    });
    dispatch(start_loading({}));
  };

  const onCancelError = () => {
    seTerrorList({
      have_error: false,
      errors: [],
      burtgel_errors: {},
    });
  };

  const onCancelErrorHurungu = () => {
    seTerrorListHurungu({
      have_error: false,
      errors: [],
      burtgel_errors: {},
    });
  };

  const title = () => (
    <Row>
      <>
        {(tableData?.total_list?.dah_result > 0.0
        || tableData?.total_list?.zuruu > 0.0) && (
          <Warning
            show
            listWarning={[
              tableData?.total_list?.dah_result > 0.0
                ? 'Өртгийн өөрчлөлт дүн таараагүй байна'
                : '',
              tableData?.total_list?.zuruu > 0.0
                ? 'Холбосон дүн таараагүй байна'
                : '',
            ].filter((text) => text !== '')}
          />
        )}
      </>
      <CanView allowedRole="006_1">
        <AntButton
          className="add-btn"
          type="primary"
          size="small"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            seTvisibleEH(true);
          }}
        >
          C1 оруулах
        </AntButton>
      </CanView>
      {/* tableData */}
      <>
        {tableData?.total_list?.zuruu_dahin && (
          <Warning
            show
            listWarning={['Өртгийн өөрчлөлтийн жагсаалт оруулна уу!!'].filter(
              (text) => text !== ''
            )}
          />
        )}
      </>
      <CanView allowedRole="006_1">
        <AntButton
          className="add-btn"
          type="primary"
          size="small"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            seTvisible({
              ...visible,
              dahin: true,
            });
          }}
        >
          Өртгийн өөрчлөлтийн жагсаалт оруулах
        </AntButton>
      </CanView>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={<CalculatorOutlined />}
        onClick={() => {
          customConfirm({
            title: 'Товчоо тооцоолохыг зөвшөөрөх үү?',
            onOk: () => cal_sanhuu_tovchoo(),
          });
        }}
      >
        Товчоо тооцоолох
      </AntButton>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        onClick={() => {
          seTsumType(sumType === 'dans' ? 'hurungu' : 'dans');
        }}
      >
        {sumType === 'dans' ? 'Дансаар' : 'Хөрөнгөөр'}
      </AntButton>
    </Row>
  );
  const after_handleUpload = (content) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: data.message });
      } else {
        if (data?.content?.result_list) {
          seTerrorList({
            have_error: true,
            errors: data?.content?.result_list,
            burtgel_errors: [],
          });
        }
        newAlert({ type: 'error', msg: data.message });
      }
      // seTfileTovchoo(false);
      dispatch(end_loading({}));
    });
    // seTfileTovchoo(true);
    socket.emit('request_in', {
      channel: 'ts001_02_dans_control',
      content: {
        general_data: {
          action_type: 'c1_tovchoo_file_tailan',
          content: { ...content },
        },
        action_type: 'get_dans_dun_list',
        month: '0',
        first_year: false,
        receive_channel: 'ts003_calculate',
      },
    });
  };

  const mainTable = () => (
    <div className="table-antd-div no-scroll">
      <Table
        isSearch
        searchList={searchList}
        title={title}
        columns={ColumnsMain({ onSelectHurungu })}
        total={tableData.total_size}
        dataSource={tableData.result_list}
        scroll={{
          y: 'calc(100vh - 390px)',
        }}
        customize={() => customSummary({ data: tableData.total_list })}
      />
    </div>
  );

  const mainDansTable = () => (
    <div className="table-antd-div no-scroll">
      <Table
        isSearch
        searchList={searchList}
        title={title}
        columns={ColumnsDansMain()}
        total={tableData.total_size}
        dataSource={tableData.result_list}
        scroll={{
          y: 'calc(100vh - 390px)',
        }}
        customize={() => customDansSummary({ data: tableData.total_list })}
      />
    </div>
  );

  const onCancelBurtgel = () => {
    seTburtgel(false);
    if (updated) {
      select_data();
      seTupdated(false);
    }
  };

  const onCancelEH = () => {
    seTvisibleEH(false);
  };
  const onCancel = (index) => {
    seTvisible({
      ...visible,
      [index]: false,
    });
  };
  const onCancelErrorDahin = () => {
    seTerrorListDahin({
      have_error: false,
      errors: {},
    });
  };
  return (
    <>
      <ErrorList onCancel={onCancelError} errorList={errorList} />
      <ErrorListDahin onCancel={onCancelErrorDahin} errorList={errorListDahin} />
      {errorListHurungu.have_error ? (
        <ErrorListHurungu
          onCancel={onCancelErrorHurungu}
          errorList={errorListHurungu}
        />
      ) : null}
      <Modal
        className="custom-modal"
        title="Өмнөx жилийн 12 сарын товчоо оруулах"
        open={visibleEH}
        width={550}
        footer={null}
      >
        <NewFileTovchoo
          onCancel={() => onCancelEH()}
          after_handleUpload={after_handleUpload}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Өртгийн өөрчлөлтийн жагсаалт оруулах"
        open={visible?.dahin}
        closable={false}
        maskClosable={false}
        width={500}
        footer={null}
      >
        <NewFileAddDahin
          onCancel={() => onCancel('dahin')}
          after_handleUpload={after_handleUploadDahin}
          typesList={typesList}
        />
      </Modal>
      <BurtgeList
        hurunguData={selectedHurungu}
        closeDrawer={onCancelBurtgel}
        open={burtgel}
        setUpdated={seTupdated}
      />
      {sumType === 'dans' ? mainDansTable() : mainTable()}
    </>
  );
};

export default UndsenHurunguTovchoo;
