import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Form, Input, Row, Col } from 'antd';
import DatePicker from 'src/components/common/datePicker';
import newAlert from 'src/components/newAlert';
import { date } from 'src/core/utils/date';
import { onlyNumber, roundFloat } from 'src/core/utils/formater';
import { start_loading, end_loading } from '../../../core/actions/loader';

const TuuhenHanshRegister = ({
  onClose = () => {},
  form = null,
  valiut = '',
  handleAddRow = () => {},
}) => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);

  const [mbHansh, setMbHansh] = useState(0.0);

  const onFinish = (values) => {
    const { guilgee_ognoo, valiut_dun, valiut_hansh, ..._values } = values;
    handleAddRow({
      guilgee_ognoo: date(guilgee_ognoo),
      valiut_dun: valiut_dun.toString(),
      valiut_hansh: valiut_hansh.toString(),
      valiut,
      ..._values,
    });
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const fetchHansh = () => {
    const currentDate = form.getFieldValue('guilgee_ognoo');
    if (currentDate && valiut) {
      socket.on('request_out', (data) => {
        socket.removeAllListeners('request_out');
        if (data.status === '0') {
          setMbHansh(data?.content?.hansh);
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }
        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ss008_00_get_hansh',
        content: {
          ognoo: currentDate,
          valiut,
        },
      });
      dispatch(start_loading({}));
    } else {
      newAlert({ type: 'error', msg: 'Огноо сонгоно уу' });
    }
  };

  const dateChange = (val) => {
    form.setFieldValue('guilgee_ognoo', val);
    form.setFieldValue('valiut_hansh', 0.00);
  };

  useEffect(() => {
    form.setFieldsValue({
      valiut_hansh: roundFloat(mbHansh),
    });
  }, [mbHansh]);

  const layout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 12 },
    },
  };

  return (
      <div className="dans-medeelel-container" style={{ overflowWrap: 'break-word' }}>
        <Form
          form={form}
          onFinish={onFinish}
          className="register-form"
          {...layout}
          size="small"
        >
          <Form.Item
            name="guilgee_ognoo"
            label="Огноо"
            labelAlign="left"
            rules={[{ required: true, message: '' }]}
          >
            <DatePicker customClass="ww-100 h-25" onChange={dateChange} />
          </Form.Item>
          <Form.Item
            name="valiut_dun"
            label="Валютын дүн"
            labelAlign="left"
            rules={[{ required: true, message: '' }]}
            normalize={onlyNumber}
          >
            <Input className="w-100 text-align-r" />
          </Form.Item>
          <Form.Item
            name="valiut_turul"
            label="Валютын төрөл"
            labelAlign="left"
            initialValue={valiut}
          >
            <Input className="w-100" disabled />
          </Form.Item>
          <Form.Item
            name="valiut_hansh"
            label="Ханш"
            labelAlign="left"
            rules={[{ required: true, message: '' }]}
            normalize={onlyNumber}
          >
            <Input 
              className="w-100 text-align-r"
              suffix={
                <Button
                  type="primary"
                  size="small"
                  onClick={fetchHansh}
                  style={{
                    marginRight: '-7px',
                    height: '22px',
                    fontSize: '12px',
                    padding: '0 8px'
                  }}
                >
                  Ханш авах
                </Button>
              }
            />
          </Form.Item>
          <Row>
            <Col span={24} className="text-align-r">
              <Form.Item noStyle>
                <Button
                  onClick={onCancel}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Цуцлах
                </Button>
                <Button
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
};

export default TuuhenHanshRegister;