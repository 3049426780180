import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  Checkbox,
  Modal,
  Statistic,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import NodeRSA from 'node-rsa';
import headerImg from 'src/assets/images/logo70.jpg';
import backImg from 'src/assets/images/flag.jpg';
import newAlert from 'src/components/newAlert';
import { set_jwt } from 'src/core/actions/jwt_token';

import getDeviceInfo from 'src/core/utils/deviceDetect';
import { start_loading, end_loading } from 'src/core/actions/loader';
import VertificationDeviceModal from './vertificationDeviceModal';
import utils from './data';
import 'react-toastify/dist/ReactToastify.css';

const NewLogin = () => {
  const { Countdown } = Statistic;
  const [form] = Form.useForm();
  const key = new NodeRSA({ b: 512 });
  const [check, seTcheck] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tempJwt, seTtempJwt] = useState(null);
  const [btn, seTbtn] = useState(true);
  const [deadline, seTdeadline] = useState(null);
  const dispatch = useDispatch();
  const [classNamae, seTclassNamae] = useState('active');
  const { socket, rsa } = useSelector((state) => ({
    socket: state?.socket,
    rsa: state?.rsa,
  }));

  const deviceTokenMaker = () => {
    const device_token = localStorage.getItem('device_token');
    if (device_token) {
      return device_token;
    }
    const token = (Math.random() + 1).toString(36).substring(2, 12);
    localStorage.setItem('device_token', token);
    return token;
  };

  const handleSubmit = async ({ email, password }) => {
    try {
      socket.on('signin_rsp', (data) => {
        console.log("click");
        socket.removeAllListeners('signin_rsp');
        const { tag, content } = data;
        if (data.status.toString() === '0') {
          if (content?.device_type === 0) {
            seTtempJwt(tag.jwt_1);
            setShowModal(true);
          } else {
            socket.emit(
              'changeRoom',
              {
                jwt: tag.jwt_1,
                device_token: localStorage.getItem('device_token'),
              },
              (response) => {
                console.log('response.status===============>', response.status); // ok
                if (response?.status === 0) {
                  localStorage.setItem('taxjwt', tag.jwt_1);
                  setTimeout(() => {
                    dispatch(
                      set_jwt({
                        jwt_1: tag?.jwt_1,
                      })
                    );
                  }, 1000);
                }
              }
            );
          }
        } else {
          if (content?.disabled && !localStorage.getItem('disabledTime')) {
            localStorage.setItem('disabledTime', new Date().getTime());
            seTclassNamae('active');
            seTdeadline(Date.now() + content?.left_time);
            seTbtn(true);
          }
          newAlert({
            type: 'error',
            msg: content.Message || 'Системд алдаа гарлаа',
          });
        }
      });

      key.importKey(rsa.serverPublicKey, 'pkcs1-public-pem');
      console.log("aaa");
      const device = await getDeviceInfo();
      const device_token = deviceTokenMaker();
      const device_info = { ...device, device_token };
      console.log("start");
      socket.emit('signin', {
        email: key.encrypt(email, 'base64'),
        password: key.encrypt(password, 'base64'),
        device_info,
      });
    } catch (error) {
      newAlert({ type: 'error', msg: error || 'Системд алдаа гарлаа' });
    }
  };

  const onFinish = (values) => {
    if (check) {
      localStorage.setItem('myEmail', values?.email);
    }
    handleSubmit(values);
  };

  const onClear = () => {
    localStorage.removeItem('disabledTime');
    seTbtn(false);
    seTclassNamae('inactive');
  };

  useEffect(() => {
    const email = localStorage.getItem('myEmail');
    const disabledTime = localStorage.getItem('disabledTime');
    if (email) {
      form.setFieldsValue({ email });
    }
    if (disabledTime) {
      seTclassNamae('active');
      seTdeadline(
        Date.now() + parseFloat(disabledTime) + 900000 - new Date().getTime()
      );
      if (parseFloat(disabledTime) + 900000 < new Date().getTime()) {
        localStorage.removeItem('disabledTime');
        seTbtn(false);
        seTclassNamae('inactive');
      }
    } else {
      seTbtn(false);
      seTclassNamae('inactive');
    }
  }, []);

  const onCheck = ({ target: { checked } }) => {
    seTcheck(checked);
  };

  const submitVerificationCode = (verification_code) => {
    socket.on('signin_rsp', (data) => {
      socket.removeAllListeners('signin_rsp');
      if (data.status === '0') {
        // newAlert({ type: 'success', msg: 'Амжилттай илгээлээ' });
      } else {
        newAlert({
          type: 'error',
          msg: data?.content?.Message || 'Системд алдаа гарлаа',
        });
      }
      dispatch(end_loading({}));
    });
    key.importKey(rsa.serverPublicKey, 'pkcs1-public-pem');
    const encrypted_verification_code = key.encrypt(
      verification_code,
      'base64'
    );
    socket.emit('device_verify', {
      content: {
        verification_code: encrypted_verification_code,
        device_token: localStorage.getItem('device_token') || null,
      },
      tag: { jwt_1: tempJwt, socket_id: socket.id },
    });
    dispatch(start_loading({}));
  };

  return (
    <>
      <Row type="flex" className="login-container">
        <Col xs={0} sm={0} md={9} lg={12} className="white-color">
          <div className="back-img">
            <img src={backImg} alt="flag" />
          </div>
        </Col>
        <Col xs={24} sm={24} md={15} lg={12} className="white-color">
          <div className="form-new-login">
            <div className="containers">
              <div>
                <div className="field-container">
                  <div className="header">
                    <div className="header-image">
                      <img src={headerImg} alt="tax-acc-logo" />
                    </div>
                  </div>
                  <Form className="new-login" form={form} onFinish={onFinish}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Имэйл хаяг оруулна уу',
                        },
                        { type: 'email', message: 'Зөв имэйл оруулна уу' },
                      ]}
                    >
                      <Input
                        id="email"
                        type="email"
                        size="large"
                        placeholder="Имэйл хаяг"
                        style={{
                          width: '100%',
                        }}
                        prefix={utils.userIcon}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Нууц үг хоосон байна',
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password
                        style={{
                          width: '100%',
                        }}
                        size="large"
                        placeholder="Нууц үг"
                        prefix={utils.lockIcon}
                      />
                    </Form.Item>
                    <div className="ww-100 mb-8">
                      <Checkbox checked={check} onChange={onCheck}>
                        Сануулах
                      </Checkbox>
                    </div>
                    <div className="login_btn">
                      <div className={classNamae}>
                        <Countdown
                          title="Нэвтэрж болох хугацаа"
                          value={deadline}
                          onFinish={onClear}
                        />
                      </div>
                    </div>

                    <Button
                      disabled={btn}
                      htmlType="submit"
                      type="primary"
                      className="submit-btn"
                    >
                      Нэвтрэх
                    </Button>

                    <div className="footer">
                      {/* <a
                        style={{
                          color: '#02c0df',
                        }}
                        href="/registerNew"
                      >
                        Бүртгүүлэх
                      </a> */}
                      <a
                        style={{
                          color: '#02c0df',
                        }}
                        href="/forgotpassword"
                      >
                        Нууц үгээ мартсан уу?
                      </a>
                    </div>
                  </Form>
                </div>
              </div>
              <span className="footer-text">Copyright © Unitax TIN 2024</span>
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        title="Бүртгэлгүй төхөөрөмж баталгаажуулах"
        open={showModal}
        className="device-modal"
        closable={false}
        maskClosable={false}
        width={600}
        footer={null}
        onCancel={() => {
          setShowModal(false);
        }}
      >
        <VertificationDeviceModal
          submitCode={submitVerificationCode}
          jwtToken={tempJwt}
        />
      </Modal>
    </>
  );
};

export default NewLogin;
